<template>
  <div class="modal" id="modal-version-location">
    <div class="modal-dialog modal-dialog--800">
      <div class="modal-content">
        <div class="modal-body-loc">
          <div
            class="modal-body-loc__searchBox d-flex flex-row flex-nowrap align-center"
            v-show="!loading"
          >
            <v-text-field
              autocomplete="off"
              type="text"
              class="input__buscador"
              v-model="search"
              density="compact"
              :disabled="loading"
              hide-details
              clearable
              @click:clear="clearFilter"
              @keyup.enter="searchMachine"
            />
            <v-btn
              @click="searchMachine"
              variant="outlined"
              rounded="1"
              type="button"
              :disabled="loading"
            >
              {{ $t("common.buttons.search") }}
            </v-btn>

            <v-checkbox
              v-model="checkAll"
              type="checkbox"
              class="check__all_locations"
              color="info"
              hide-details
              :disabled="loading"
              width="50"
              @click="checkAllChoice($event)"
            />
          </div>

          <div
            class="modal-body-loc__loading"
            id="loading-modal"
            v-if="
              (!locationSelector.sending && loading) ||
              (!locationSelector.sending && masters.globalLoading) ||
              notFound
            "
          >
            <v-progress-circular
              v-show="
                loading || (!locationSelector.sending && masters.globalLoading)
              "
              indeterminate="disable-shrink"
            />

            <div class="loading-modal__info-text">{{ loading_text }}</div>
          </div>

          <div v-else class="modal-body-loc__result">
            <div
              v-for="energy in energyType"
              v-show="energy.found"
              :id="energy.ORGID"
              :key="energy"
            >
              <div class="energia">
                <div class="energia_name" @click="hide(energy, 0)">
                  <v-icon
                    :class="{ left: energy.display }"
                    class="icon__arrow-center"
                    icon="mdi-chevron-right"
                  >
                  </v-icon>
                  <div class="icon energy-icon">
                    <img
                      :src="getIcon(energy.ORGID)"
                      :alt="`energy_${energy.ORGID}`"
                    />
                  </div>
                  <div class="name">
                    <p class="selector__text">{{ title(energy.ORGID) }}</p>
                  </div>
                  <div class="selector__counter">
                    <p class="selector__text total">
                      {{ energy.children }}/{{ energy.totalChildren }}
                    </p>
                  </div>
                </div>
                <div class="check__cnt check__cnt--energy"></div>
              </div>

              <div
                v-for="agrupacion in filterAgrupaciones(energy)"
                v-show="energy.display && agrupacion.found"
                :key="agrupacion.SITEID"
                :id="agrupacion.SITEID"
              >
                <div class="agrupacion">
                  <v-icon
                    class="icon__arrow-center"
                    @click="hide(agrupacion, 1)"
                    :class="{ left: agrupacion.display }"
                    icon="mdi-chevron-right"
                  >
                  </v-icon>
                  <div class="name" @click="hide(agrupacion, 1)">
                    <p class="selector__text">{{ agrupacion.SITEDESC }}</p>
                  </div>
                  <div class="selector__counter">
                    <p class="selector__text total">
                      {{ agrupacion.children }}/{{ agrupacion.totalChildren }}
                    </p>
                  </div>
                  <div class="check__cnt check__cnt--group">
                    <input
                      class="selector__check check"
                      type="checkbox"
                      @change="groupChange(agrupacion, $event)"
                      :id="agrupacion.SITEID + '_check'"
                      v-model="agrupacion.check"
                    />
                  </div>
                </div>

                <div
                  class="parques"
                  v-for="parque in filterParques(agrupacion)"
                  v-show="agrupacion.display && parque.found"
                  :key="parque.ID"
                  :id="parque.ID"
                >
                  <div class="parque">
                    <v-icon
                      class="icon__arrow-center"
                      @click="hide(parque)"
                      :class="{ left: parque.display }"
                      icon="mdi-chevron-right"
                    >
                    </v-icon>
                    <div class="name" @click="hide(parque)">
                      <p class="selector__text">{{ parque.DESWINDFARM }}</p>
                    </div>
                    <div class="selector__counter">
                      <p class="selector__text total">
                        {{ parque.children }}/{{ parque.totalChildren }}
                      </p>
                    </div>
                    <div class="check__cnt check__cnt--park">
                      <input
                        class="selector__check check"
                        type="checkbox"
                        @change="parkChange(parque.SITEID, parque.ID, $event)"
                        :id="parque.ID + '_check'"
                        v-model="parque.check"
                      />
                    </div>
                  </div>
                  <div
                    v-for="(maquina, index) in parque.maquinas"
                    v-show="parque.display && maquina.found"
                    :key="index"
                    :id="index"
                  >
                    <div class="maquinas" :class="machineStyle(index)">
                      <div class="name">
                        <p class="selector__text">{{ maquina.DESLOCATION }}</p>
                      </div>
                      <div
                        class="check__cnt check__cnt--machine"
                        :class="checkStyle(index)"
                      >
                        <input
                          class="selector__check check"
                          type="checkbox"
                          @change="machineChange(maquina.IDPark, $event)"
                          :id="maquina.LOCATION + '_check'"
                          v-model="maquina.check"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
    <CommonModal
      :dialog="checkModal"
      @cancel-click="
        checkModal = false;
        checkAll = false;
      "
      :title="$t('locationSelector.title')"
      :confirm="false"
    >
      <div class="row">
        <div class="col-xs-12 modal__checkbox-space">
          {{ $t("locationSelector.modal.applyText") }}
        </div>
        <div class="col-xs-12 modal__checkbox-space">
          <v-btn
            variant="outlined"
            rounded="1"
            type="button"
            @click="applyFilter('only')"
          >
            {{ $t("locationSelector.modal.applyFilter") }}
          </v-btn>

          <v-btn
            variant="outlined"
            rounded="1"
            type="button"
            id="check__all_locations"
            @click="applyFilter('all')"
          >
            {{ $t("locationSelector.modal.applyAll") }}
          </v-btn>
        </div>
      </div>
    </CommonModal>
    <CommonProgressBarInfo
      v-if="locationSelector.sending"
      :text="$t('locationSelector.modal.sending')"
      :percentage="locationSelector.sendingPercentage"
    />
  </div>
</template>
    
<script setup>
import { useMasters } from "~/store/masters";
import { useLocationSelector } from "~/store/locationSelector";

const loading = ref(false);

const locationSelector = useLocationSelector();
const masters = useMasters();

const machine_list = ref(false);
const agrupaciones = ref([]);
const parques = ref([]);
const energyType = ref([]);
const hideIDs = ref([]);
const search = ref("");
const checkModal = ref(false);
const checkAll = ref(false);

const notFound = ref(false);
const filtered = ref(false);
const checkAllChecked = ref(false);
const loading_text = ref("Obteniendo ubicaciones del usuario");

watch(
  machine_list,
  async (val) => {
    if (val) {
      loading.value = true;
      await fillList().then(stoploading);
    }
  },
  { deep: true }
);

onMounted(async () => {
  loading.value = true;
  setTimeout(async () => {
    await fillList();
    stoploading();
  }, 100);
});

const filterAgrupaciones = (energy) => {
  return agrupaciones.value.filter((agrupacion) => {
    return isParent(energy?.ORGID, agrupacion?.ORGID);
  });
};
const filterParques = (agrupacion) => {
  const parquesFilter = parques.value.filter((parque) => {
    return isParent(agrupacion?.SITEID, parque?.SITEID);
  });
  return parquesFilter;
};

const stoploading = () => {
  loading.value = false;
};

const fillList = async () => {
  if (masters.machines.totalList.length == 0) await masters.getAllMachinesWS();

  const machines_list = masters.machines.totalList;

  machines_list.forEach((element) => {
    getEnergy(element.ORGID);
    getGroup(element.SITEID, element.SITEDESC, element.ORGID);
    getParks(element.LOCATION, element.DESWINDFARM, element.SITEID);
    getMachine(
      element.LOCATION,
      element.SITEID,
      element.DESLOCATION,
      element.VISUALIZA
    );
  });

  energyType.value.sort((a, b) => {
    const titleA = title(a.ORGID).toUpperCase(); // Ignora mayúsculas y minúsculas
    const titleB = title(b.ORGID).toUpperCase(); // Ignora mayúsculas y minúsculas
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  agrupaciones.value.sort((a, b) => {
    const siteDescA = a.SITEDESC.toUpperCase(); // Ignora mayúsculas y minúsculas
    const siteDescB = b.SITEDESC.toUpperCase(); // Ignora mayúsculas y minúsculas
    if (siteDescA < siteDescB) {
      return -1;
    }
    if (siteDescA > siteDescB) {
      return 1;
    }
    return 0;
  });

  parques.value.sort((a, b) => {
    // Primero, ordena las máquinas dentro de cada parque
    a.maquinas.sort((maquinaA, maquinaB) => {
      const deslocationA = maquinaA.DESLOCATION.toUpperCase(); // Ignora mayúsculas y minúsculas
      const deslocationB = maquinaB.DESLOCATION.toUpperCase(); // Ignora mayúsculas y minúsculas
      if (deslocationA < deslocationB) {
        return -1;
      }
      if (deslocationA > deslocationB) {
        return 1;
      }
      return 0;
    });

    // Luego, ordena los parques
    const desWindFarmA = a.DESWINDFARM.toUpperCase(); // Ignora mayúsculas y minúsculas
    const desWindFarmB = b.DESWINDFARM.toUpperCase(); // Ignora mayúsculas y minúsculas
    if (desWindFarmA < desWindFarmB) {
      return -1;
    }
    if (desWindFarmA > desWindFarmB) {
      return 1;
    }
    return 0;
  });

  reviewStateCheckAllLocations();
  // stoploading();
};

const getEnergy = (ORGID) => {
  const tmp = energyType.value.find((element) => element.ORGID == ORGID);

  if (typeof tmp == "undefined") {
    energyType.value.push({
      ORGID: ORGID,
      display: false,
      found: true,
      check: false,
      children: 0,
      totalChildren: 0,
    });
  }
};

const getGroup = (SITEID, SITEDESC, ORGID) => {
  const tmp = agrupaciones.value.find((element) => element.SITEID == SITEID);

  if (typeof tmp == "undefined") {
    agrupaciones.value.push({
      SITEID: SITEID,
      SITEDESC: SITEDESC,
      ORGID: ORGID,
      check: false,
      display: false,
      found: true,
      children: 0,
      totalChildren: 0,
    });
  }
};

const getParks = (LOCATION, DESWINDFARM, SITEID) => {
  const id = LOCATION.split(".", 1)[0] + SITEID;
  const tmp = parques.value.find((element) => element.ID == id);

  if (typeof tmp == "undefined") {
    parques.value.push({
      ID: id,
      DESWINDFARM: DESWINDFARM,
      SITEID: SITEID,
      display: false,
      found: true,
      check: false,
      children: 0,
      maquinas: [],
      totalChildren: 0,
    });
  }
};

const getMachine = (LOCATION, SITEID, DESLOCATION, VISUALIZA) => {
  const id = LOCATION.split(".", 1)[0] + SITEID;
  const idxPark = parques.value.findIndex((element) => element.ID == id);
  const idxGroup = agrupaciones.value.findIndex(
    (element) => element.SITEID == SITEID
  );
  const idxOrg = energyType.value.findIndex(
    (element) => element.ORGID == agrupaciones.value[idxGroup].ORGID
  );
  const checked = VISUALIZA === "1";

  parques.value[idxPark].totalChildren += 1;
  agrupaciones.value[idxGroup].totalChildren += 1;
  energyType.value[idxOrg].totalChildren += 1;

  if (checked) {
    parques.value[idxPark].children += 1;
    agrupaciones.value[idxGroup].children += 1;
    energyType.value[idxOrg].children += 1;

    parques.value[idxPark].check = true;
    agrupaciones.value[idxGroup].check = true;
    energyType.value[idxOrg].check = true;
  }
  parques.value[idxPark].maquinas.push({
    IDPark: id,
    LOCATION: LOCATION,
    DESLOCATION: DESLOCATION,
    SITEID: SITEID,
    check: checked,
    found: true,
  });

  energyType.value[idxOrg].check =
    energyType.value[idxOrg].children ===
    energyType.value[idxOrg].totalChildren;
  agrupaciones.value[idxGroup].check =
    agrupaciones.value[idxGroup].children ===
    agrupaciones.value[idxGroup].totalChildren;
  parques.value[idxPark].check =
    parques.value[idxPark].children === parques.value[idxPark].totalChildren;
};

const isParent = (child, parent) => {
  return child == parent;
};
const checkStyle = (index) => {
  return index % 2 == 0 ? "oscuro" : "claro";
};

const machineStyle = (index) => {
  return index % 2 == 0 ? "gris" : "blanco";
};

const hide = (parent, type) => {
  if (parent.display) {
    parent.display = false;
    hideChildren(parent, type);
  } else {
    parent.display = true;
  }
};

const hideChildren = (parent, type) => {
  if (type == 0) {
    agrupaciones.value.forEach((group) => {
      if (group.ORGID == parent.ORGID) {
        group.display = false;
        parques.value.forEach((park) => {
          if (park.SITEID == group.SITEID) {
            park.display = false;
          }
        });
      }
    });
  } else if (type == 1) {
    parques.value.forEach((park) => {
      if (park.SITEID == parent.SITEID) {
        park.display = false;
      }
    });
  }
};

const title = (energy) => {
  let energy_title = "";
  switch (energy) {
    case "WIND":
      energy_title = "EÓLICA";
      break;
    case "HID":
      energy_title = "HIDRÁULICA";
      break;
    case "BIOM":
      energy_title = "BIOMASA";
      break;
    case "PV":
      energy_title = "FOTOVOLTAICA";
      break;
    case "TERM":
      energy_title = "TÉRMICA";
      break;
    case "BESS":
      energy_title = "ALMACENAMIENTO";
      break;
    default:
      energy_title = "OTROS";
      break;
  }

  return `${energy_title} (${energy})`;
};

const getIcon = (name) => {
  const energy_types = ["WIND", "TERM", "PV", "HID", "BIOM", "BESS"];
  const img_name = energy_types.includes(name) ? name : "OTROS";
  return `/img/location/${img_name}.png`;
};

const machineChange = (idParent, e) => {
  const parkIdx = parques.value.findIndex((element) => element.ID == idParent);
  const groupIdx = agrupaciones.value.findIndex(
    (element) => element.SITEID == parques.value[parkIdx].SITEID
  );
  const energyIdx = energyType.value.findIndex(
    (element) => element.ORGID == agrupaciones.value[groupIdx].ORGID
  );

  if (e.target.checked) {
    parques.value[parkIdx].children += 1;
    agrupaciones.value[groupIdx].children += 1;
    energyType.value[energyIdx].children += 1;
  } else {
    parques.value[parkIdx].children -= 1;
    agrupaciones.value[groupIdx].children -= 1;
    energyType.value[energyIdx].children -= 1;
  }

  energyType.value[energyIdx].check =
    energyType.value[energyIdx].children ===
    energyType.value[energyIdx].totalChildren;
  agrupaciones.value[groupIdx].check =
    agrupaciones.value[groupIdx].children ===
    agrupaciones.value[groupIdx].totalChildren;
  parques.value[parkIdx].check =
    parques.value[parkIdx].children === parques.value[parkIdx].totalChildren;

  reviewStateCheckAllLocations();
};

const parkChange = (idParent, id, e) => {
  const idx = agrupaciones.value.findIndex(
    (element) => element.SITEID == idParent
  );
  const energyIdx = energyType.value.findIndex(
    (element) => element.ORGID == agrupaciones.value[idx].ORGID
  );
  const park = parques.value.find((element) => element.ID == id);

  if (e.target.checked) {
    park.maquinas.forEach((maq) => {
      if (!maq.check) {
        maq.check = true;
        agrupaciones.value[idx].children += 1;
        energyType.value[energyIdx].children += 1;
      }
    });
    park.children = park.totalChildren;
  } else {
    park.maquinas.forEach((maq) => {
      if (maq.check) {
        maq.check = false;
        agrupaciones.value[idx].children -= 1;
        energyType.value[energyIdx].children -= 1;
      }
    });
    park.children = 0;
  }

  agrupaciones.value[idx].check =
    agrupaciones.value[idx].children === agrupaciones.value[idx].totalChildren;
  energyType.value[energyIdx].check =
    energyType.value[energyIdx].children ===
    energyType.value[energyIdx].totalChildren;
  park.check = park.children === park.totalChildren;

  reviewStateCheckAllLocations();
};

const groupChange = (group, e) => {
  let energyIdx = energyType.value.findIndex(
    (element) => element.ORGID == group.ORGID
  );

  if (e.target.checked) {
    group.children = group.totalChildren;

    parques.value.forEach((park) => {
      // Si el parque está en la agrupación y no está marcado entonces se marca
      if (park.SITEID == group.SITEID && !park.check) {
        energyType.value[energyIdx].children += park.totalChildren;
        park.check = true;
        park.maquinas.forEach((maq) => {
          maq.check = true;
        });
        park.children = park.maquinas.length;
      }
    });
  } else {
    parques.value.forEach((park) => {
      // Si el parque está en la agrupación y está marcada entonces se desmarca
      if (park.SITEID == group.SITEID && park.check) {
        energyType.value[energyIdx].children -= park.children;
        park.check = false;
        park.maquinas.forEach((maq) => {
          maq.check = false;
        });
        park.children = 0;
      }
    });
    hasChildrenLeft(energyType.value[energyIdx]);
    group.children = 0;
  }

  // Marcar o desmarcar el check de marcar todas las máquinas de energía
  energyType.value[energyIdx].check =
    energyType.value[energyIdx].children ===
    energyType.value[energyIdx].totalChildren;

  // Revisar si hay que marcar o desmarcar el check de todas las ubicaciones
  reviewStateCheckAllLocations();
};
const hasChildrenLeft = (parent) => {
  if (parent.children == 0) {
    parent.check = false;
  }
};

const energyChange = (energy, e, only_filtered) => {
  let checkState = e.target.checked;

  // Navegar por las agrupaciones para marcar o desmarcar
  agrupaciones.value.forEach((group) => {
    if (group.ORGID === energy.ORGID) {
      // Comprobar si hay que marcar solo las ubicaciones filtradas
      if (only_filtered) {
        if (group.found) {
          parques.value.forEach((parque) => {
            if (parque.SITEID === group.SITEID && parque.found) {
              checkPark(parque, group, energy, checkState, only_filtered);
            }
          });
        }
      } else {
        parques.value.forEach((parque) => {
          if (parque.SITEID === group.SITEID) {
            checkPark(parque, group, energy, checkState, only_filtered);
          }
        });
      }

      // Marcar o desmarcar el check de todas las máquinas seleccionadas
      group.check = group.children === group.totalChildren;
    }
  });
};

const checkPark = (parque, group, energy, checkValue, only_filtered) => {
  // Marcar o desmarcar las máquinas de la instalación
  parque.maquinas.forEach((maq) => {
    if (only_filtered) {
      if (maq.found) {
        checkMachine(maq, parque, group, energy, checkValue);
      }
    } else {
      checkMachine(maq, parque, group, energy, checkValue);
    }
  });

  // Marcar o desmarcar el check de todas las máquinas seleccionadas
  parque.check = parque.children === parque.totalChildren;
};

const checkMachine = (maq, parque, group, energy, checkValue) => {
  // Actualizar los contadores solo en los casos donde la máquina no estuviese marcada o se vaya a desmarcar
  if (checkValue && !maq.check) {
    // Si la máquina no esta marcada, entonces se marca
    parque.children += 1;
    group.children += 1;
    energy.children += 1;
  } else if (!checkValue && maq.check) {
    // Si la máquina esta marcada, entonces se desmarca
    parque.children -= 1;
    group.children -= 1;
    energy.children -= 1;
  }
  maq.check = checkValue;
};

const reviewStateCheckAllLocations = () => {
  let totalMachines = 0;
  let checkedMachines = 0;

  parques.value.forEach((park) => {
    totalMachines += park.totalChildren;
    checkedMachines += park.children;
  });

  checkAllChecked.value = totalMachines === checkedMachines;
  // Clono a la store para poder lanzar el guardado
  locationSelector.setParques(JSON.parse(JSON.stringify(parques.value)));
};

const hideMachine = (machine, idParent, type) => {
  if (machine.display && type == 0) {
    hideIDs.value.push(machine.LOCATION);
    machine.display = false;
  } else if (machine.display && type == 1) {
    const idx = hideIDs.value.findIndex((id) => id == machine.LOCATION);
    hideIDs.value.splice(idx, 1);
    machine.display = true;
  }
};

const checkAllChoice = (e) => {
  if (filtered.value) {
    if (checkAll.value) return;
    // Vuelvo el check a su estado anterior
    set_check(
      ".check__all_locations",
      !is_checkbox_checked(".check__all_locations")
    );

    checkModal.value = true;
  } else {
    checkAllLocations(e, false);
  }
};

const is_checkbox_checked = (element) => {
  const el = document.querySelector(element);
  return el ? el.checked : false;
};

const checkAllLocations = (e, only_filtered) => {
  let numTotalChildrenChecked = 0;
  let numTotalChildren = 0;

  // Marcar o desmarcar todas las ubicaciones
  energyType.value.forEach((energy) => {
    if (only_filtered) {
      if (energy.found) {
        energyChange(energy, e, only_filtered);
      }
    } else {
      energyChange(energy, e, only_filtered);
    }

    numTotalChildrenChecked += energy.children;
    numTotalChildren += energy.totalChildren;
  });

  // Marcar o desmarcar el check de todas las máquinas seleccionadas
  set_check(
    ".check__all_locations",
    numTotalChildrenChecked === numTotalChildren
  );
  checkModal.value = false;
};

/**
 * Función encargada de establecer o quitar el check a un checkbox
 * @param {string} element identificador del input check del DOM
 * @param {boolena} value True | False - Indica el valor del input check
 */
const set_check = (element, value) => {
  const el = document.querySelector(element);
  if (el) {
    el.checked = value;
  }
};

const clearFilter = () => {
  search.value = "";
  searchMachine();
};

const searchMachine = () => {
  // Marcar si el filtro se va a activar o no
  filtered.value = search.value !== "";

  // Filtrar los datos
  parques.value.forEach((parque) => {
    let found = false;
    parque.maquinas.forEach((maquina) => {
      const idx = agrupaciones.value.findIndex(
        (element) => element.SITEID == parque.SITEID
      );
      if (
        maquina.DESLOCATION.toUpperCase().includes(
          search.value.toUpperCase()
        ) ||
        search.value === ""
      ) {
        found = true;
        maquina.found = true;
      } else {
        maquina.found = false;
      }

      if (!found) {
        parque.found = false;
      } else {
        parque.found = true;
        parque.display = search.value !== "";
        agrupaciones.value[idx].found = true;
      }
    });
  });
  foundGroup();

  // Revisar si se ha encontrado resultados. En el caso de que no se muestra un mensaje
  checkSearchResult();
};

const checkSearchResult = () => {
  // Comprobamos si ha habido algún resultado con la búsqueda
  const some_result = energyType.value.some((energy) => energy.found);

  if (!some_result) {
    notFound.value = true;
    loading_text.value =
      "No se han encontrado resultados con el término buscado.";
  } else {
    notFound.value = false;
    loading_text.value = "";
  }
};

const foundGroup = () => {
  agrupaciones.value.forEach((group) => {
    const foundedGroup = parques.value.find(
      (parque) => parque.SITEID == group.SITEID && parque.found
    );
    group.found = typeof foundedGroup !== "undefined";
    group.display = search.value !== "";
  });
  foundEnergy();
};

const foundEnergy = () => {
  energyType.value.forEach((energy) => {
    const foundedGroup = agrupaciones.value.find(
      (group) => group.ORGID == energy.ORGID && group.found
    );
    energy.found = typeof foundedGroup !== "undefined";
    energy.display = search.value !== "";
  });
};

const applyFilter = (type) => {
  let checkEvent = {
    target: { checked: is_checkbox_checked(".check__all_locations") },
  };
  if (type == "all") {
    checkAllLocations(checkEvent, false);
  } else if (type == "only") {
    checkAllLocations(checkEvent, true);
  }
  checkAll.value = false;
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#modal-version-location > .modal-dialog--800.modal-dialog--800 {
  width: 100% !important;
}

#loading-modal {
  width: 100%;
  height: calc(100% - 31px); /* Toda la altura menos el footer */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 1rem;
}

.loading-modal__image {
  max-width: 5rem;
}

.loading-modal__info-text {
  font-style: italic;
}

#modal-version-location > .modal-dialog {
  width: 100% !important;
}

#modal-version-location > .modal-dialog > .modal-content {
  width: 100%;
  max-width: none;
}

.modal-header .modal_close {
  top: $space-xs !important;
  right: $space-md !important;
}

.modal-body-loc {
  height: 600px;
  position: relative;
  width: 100%;
}

.modal-body-loc__searchBox {
  align-items: center;
  padding-top: $space-sm;
  padding-bottom: $space-sm;
  text-align: center;
  display: flex;
  position: absolute;
  background-color: #ffffff;
  margin: 0 $space-md;
  z-index: 1;
  left: 0;
  right: 0;
}

.modal-body-loc > #img-load {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: initial;
  width: 60px;
  height: 60px;
  margin-top: $space-sm;
  margin-bottom: $space-md;
}

.modal-body-loc__result {
  overflow-y: auto;
  height: 100%;
  padding-top: $space-xxl; /* Altura del buscador */
}

.energia {
  height: $space-xl;
  display: flex;
  border-top: $space-xxs solid black;
  border-bottom: 1px solid rgb(231, 231, 231);
  margin-left: $space-md;
  margin-right: $space-md;
}

.energia_name {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.agrupacion {
  margin-left: $space-xl;
  height: $space-xl;
  display: flex;
  border-bottom: 1px solid rgb(231, 231, 231);
  margin-right: $space-md;
  cursor: pointer;
}

.parque {
  height: $space-xl;
  display: flex;
  border-bottom: 1px solid rgb(231, 231, 231);
  margin-left: 75px;
  background-color: rgb(249, 249, 249);
  margin-right: $space-md;
  cursor: pointer;
}

.maquinas {
  height: $space-xl;
  display: flex;
  border-bottom: 1px solid rgb(231, 231, 231);
  margin-left: 105px;
  margin-right: $space-md;
}

.selector__check.selector__check {
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.oscuro {
  background-color: #a0a0a0;
}

.claro {
  background-color: #e7e7e7;
}

.blanco {
  background-color: #ffffff;
}

.gris {
  background-color: #e7e7e7;
}

.name {
  width: 100%;
  height: 100%;
}

.selector__text {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: $space-sm;
}

.selector__counter {
  text-align: right;
}

.check__cnt {
  display: flex;
  cursor: default;
}

.check__cnt--energy {
  min-width: $space-xxl;
}

.check__cnt--group {
  background-color: rgb(249, 249, 249);
}

.check__cnt--park {
  border-bottom: 1px solid rgb(231, 231, 231);
  background-color: rgb(231, 231, 231);
  display: flex;
  cursor: default;
}

.check__cnt--machine {
  display: flex;
}

.icon {
  height: 25px;
  margin-top: auto;
  width: 25px;
  margin-bottom: auto;
  flex: 1 0 auto;
}
.energy-icon {
  // margin-left: 10px;

  & > img {
    object-fit: contain;
    margin-top: $space-xxs;
    width: 100%;
  }
}

.input__buscador {
  width: 65%;
  padding-right: 20px;
}

.check__all_locations {
  margin: 0 0px 0 $space-md !important;
  width: 24px;
}

.left {
  rotate: 90deg;
}

.total {
  position: relative;
  margin-bottom: auto;
  margin-right: $space-md;
}

.arrow {
  margin-top: auto;
  margin-bottom: auto;
  width: 3rem;
  text-align: center;
}

#check__all_locations {
  float: right;
}
.icon__arrow-center {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: $space-sm;
}

.modal__checkbox-space {
  margin: 20px;
}
</style>
  
<template>
  <div class="background" v-show="info_data.show">
    <div class="alert__center">
      <v-alert>
        <template #text>
          <div v-html="info_data.text" style="max-width: 480px"></div>
        </template>

        <v-progress-linear
          class="mt-4"
          color="dark-lighten-3"
          indeterminate
          absolute
          location="bottom"
        ></v-progress-linear>
      </v-alert>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from "vue";
import { useWarningStore } from "~/store/globalMessage";
import { useMasters } from "~/store/masters";

const globalMessage = useWarningStore();
const masters = useMasters();

const info_data = computed(() => globalMessage.info);

watch(
  info_data,
  (newInfo) => {
    masters.setGlobalLoading(newInfo.show);
    masters.setOnCloseConfirm(newInfo.show);
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
</style>